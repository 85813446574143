.form {
    background: theme-color(light-gray);
    padding: 1rem;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &__error, &__success {
        display: none; // will be shown from JavaScript
        text-align: center;
        max-width: 40em;
        margin: auto;

        h4 {
            margin-top: 0;
        }

        p {
            font-size: 0.9375em;
            -webkit-hyphens: none;
            hyphens: none;

            b {
                font-weight: normal;
                color: black;
            }
        }

        .button {
            min-width: 20em;
            margin-top: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}
