.related-pages {
    display: flex;
    padding: 0;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
    }

}

.related-page-box {

    display: block;
    padding: 0.8em 0.8em 1.5em;
    list-style: none;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;

    margin-bottom: 1em;

    transition-property: border, box-shadow;
    transition-duration: 150ms;

    font-size: 0.9rem;
    border: 1px solid #ddd;


    color: inherit;

    &:hover, &:active, &:focus {
        color: inherit;
        text-decoration: none;
        border: 1px solid #bbb;
        outline: none !important;
    }

    &:hover {
        box-shadow: #ccc 1px 1px 4px;
    }

    &:active {
        box-shadow: none !important;
    }

    strong {
        display: block;
        margin: 0.7em 0 0.4em;
        font-family: $headings-font-family;
        color: #000;
        font-size: 1.3rem;
        line-height: 1.4;
        font-weight: normal;
    }

    p {
        color: #888;
        margin-bottom: 0;
    }

    &__image {
        display: none;
        margin-bottom: 0.5em;
    }

    @include media-breakpoint-up(md) {
        margin: 0 1em;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &__image {
            display: block;
        }
    }

}
