.trial-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .domain .tld {
        display: inline-block;
        color: #333;
        padding-top: 0.4em;
        padding-left: 0.5em;
    }

    &__actions-panel {
        text-align: right;
        .form-field__field {
            margin-left: 0;
        }

        .button {
            width: 100%;
            position: relative;
        }

        .button--loading::before {
            content: ' ';
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: 0.5em;
            right: 1.5em;
            background: url('loading.gif') no-repeat left bottom;
        }
    }

    h4 {
        margin-bottom: 1rem;
    }


    @include media-breakpoint-up(md) {
        margin: 2em auto 3em;
        padding: 1.5rem 1rem;

        h4 {
            margin-bottom: 1.5rem;
        }

        .form-field {
            padding-right: 1rem;
        }

        &__data-panel {
            display: flex;
        }

        &__system-data-panel {
            flex: 1;

            .form-field__label {
                min-width: 5em;
            }

            .domain {
                margin-top: 1.5rem;
                margin-bottom: 0;

                input {
                    width: 60%;
                    max-width: 12em;
                }

            }
        }

        &__personal-data-panel {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;

            padding-left: 1rem;
            border-left: 1px solid #d0d0d0;
        }

        &__actions-panel {
            .button {
                width: auto;
                min-width: calc(50% - 1rem);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 1.5rem 1rem 0.5rem 1.5rem;

        padding-left: 1.5rem;
        padding-right: 0.5rem;

        .form-field {
            padding-right: 1.5rem;
        }

        &__personal-data-panel {
            flex: 2;

            h4 {
                width: 100%;
            }

            .form-field {
                width: 50%;
            }

            .form-field--checkbox .form-field__field {
                margin-left: 0 !important;
            }

            .firstname {
                order: 1;
                nav-index: 1;
            }
            .email {
                order: 2;
            }
            .lastname {
                order: 3;
                nav-index: 2;
            }
            .password {
                order: 4;
            }
            .phone {
                order: 5;
                nav-index: 3;
            }
            .terms_accepted {
                order: 6;
            }
        }

        &__actions-panel {
            .button {
                min-width: calc(33.333% - 2rem);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding-left: 2rem;
        padding-right: 1rem;

        .form-field {
            padding-right: 2rem;
        }

        &__personal-data-panel {
            flex: 1.8;
        }

        &__actions-panel {
            .button {
                min-width: calc(33.3333% - 2.5rem);
            }
        }

    }
}

@import 'questions';
