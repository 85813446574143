.menu-button {

    $menu-button-border: 1px solid theme-color("primary") !default;

    display: inline-block;
    width: auto;
    position: relative;
    padding: 0;

    .button {
        min-width: 100%;
        width: 100%;
        text-align: left;
    }

    &--fullwidth {
        width: 100%;
    }

    &__menu {
        display: none;
        position: absolute;
        z-index: 100;
        background: white;
        box-shadow: $default-box-shadow;
        padding: 0;
        border: $menu-button-border;
        border-top: none;
        width: 100%;
    }

    &__item {
       list-style: none;
        margin: 0 !important;
        padding: 0 ;
        transition: all 300ms;
        border-top: 1px solid #ccc;

        a {
            width: 100%;
            min-height: 2em;
            display: inline-block;
            padding: 0.5em 1em;
            color: inherit;
            text-decoration: none;
        }

        &:hover {
            color: #333;
            background: change-color($primary, $lightness: 85%, $saturation: 30%);
            background: #f2f2f0;
        }

        &--active {
            font-weight: bold;
            color: theme-color("primary") !important;
        }
    }
}
