.subnavigation {

    &__menu {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 0;
        font-size: 0.9375em;
    }

    &__item {
        padding: 0;
        margin: 0;

        margin: 0 !important;
        list-style: none;
        display: inline-block;
        color: rgba($text-color, 0.8);

        a {
            display: inline-block;
            padding: 0.3em 0;
            color: inherit;
            transition: all 300ms;
        }

        a:hover {
            text-decoration: none;
            color: theme-color("primary");
        }

        &--active {
            font-weight: bold;
            color: theme-color("primary");
        }
    }

    // smaller and down
    @include media-breakpoint-down('sm') {
        &__menu {
            display: none;
        }
    }

    // medium and up
    @include media-breakpoint-up('md') {
        // mobile-menu is actually a menu-button, therefore no further styling needed.
        &__mobile-menu {
            display: none;
        }
    }

    // large and up
    @include media-breakpoint-up('lg') {
        &__menu {
            justify-content: center;
        }

        &__item {
            padding: 0.3em 1em;
        }
    }
}
