body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    padding-top: 3.5em;
}

#content, .content {
    flex: 1 0 auto;
    padding-bottom: 2rem;
}

.navigation, .footer {
    flex-shrink: 0;
}

a.anchor {
    display: block;
    position: relative;
    top: -2rem;
}

.cc-window {
    font-family: $font-family-base;
    font-size: 14px;
    border: 1px solid #666;

    &.cc-floating {
        max-width: 26em;
    }
}
