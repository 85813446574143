.intro {
    font-size: 1.3rem;
    line-height: 1.7em;
    margin-bottom: 3em;
    color: #888;
    hyphens: none !important;

    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
    }
}

h1 + .intro {
    margin-bottom: 4em;
}
