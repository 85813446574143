
.pricing {

    // Layout
    .row {
        margin-bottom: 0;
    }

    p.intro {
        margin-bottom: 0.8rem;

        br {
            display: none;
        }
    }

    .pricing-plans {
        margin-bottom: 1em;

        .price-box {
            margin-bottom: 1em;
        }

    }

    &__prepaid-terms {
        font-size: 0.9375em;
        color: #999;
        text-align: center;
        line-height: 1.5;
        hyphens: none;
        margin-bottom: 1em;
    }

    &__vat {
        font-size: 0.9375em;
        color: #666;
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 3em;

        p.intro {
            margin-bottom: 1.5rem;
        }

        .pricing-plans {
            display: flex;
            flex-wrap: wrap; // wrap price boxes if needed

            > * {
                flex: 1 1 30%;
            }
        }


    }

    @include media-breakpoint-up(lg) {

        p.intro br {
            display: inherit;
        }

        .pricing-plans {
            flex-wrap: nowrap;

            .price-box:not(:first-child) {
                margin-left: 1em;
            }

            .price-box--flex {
                margin-left: 4em !important;
            }
        }

        &__vat {
            text-align: right;
        }
    }

}
