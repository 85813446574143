.contact-link {
    display: inline-block;
    position: relative;

    a {
        display: inline-block;
        padding-left: 1.75em;
        color: inherit;
    }

    a::before {
        content: ' ';
        opacity: 0.4;
        position: absolute;
        top: 0;
        left: 0;
        height: 1.5em;
        width: 1.25em;
        background: url('facebook-icon.svg') no-repeat left center / 1.25em;
    }

    &--contactform {
        a::before {
            background-image: url('contactform-icon.svg');
        }
    }

    &--email {
        a::before {
            background-image: url('email-icon.svg');
        }
    }

    &--facebook {
        a::before {
            background-image: url('facebook-icon.svg');
        }
    }

    &--phone {
        a {
            padding-left: 1.5em;
        }
        a::before {
            background-image: url('phone-icon.svg');
        }
    }
}
