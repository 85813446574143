//
// TABLES
//
table {
    width: 100%;
    margin: 1.5em 0;
}

tr:hover {
    background-color: lighten($secondary, 55%);
}

td, th {
    border: 1px solid #ddd;
    padding: 0.2em 0.5em;
    vertical-align: top;
}

th {
    background: #eee;
}
