.trial-form__questions {

    opacity: 0.75;
    font-size: 0.95em;
    padding-bottom: 3em;

    b {
        -webkit-hyphens: none;
        hyphens: none;
    }
}
