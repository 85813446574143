
// Timicx-specific variables
$footer-bg-color: #444;
$footer-text-color: white;

$default-box-shadow: 0.15rem 0.15rem 0.4rem rgba(0, 0, 0, 0.25);

// Bootstrap Variables
$font-family-base: 'Noto Sans', serif, sans-serif;
$headings-font-family: 'Fago', $font-family-base;
$text-color: #666;

$font-size-base: 1em; // 16px
$line-height-base: 1.55;

$enable-gradients: true;
$enable-shadows: true;
$theme-colors: (
        primary: #44cc00,
        secondary: #08c,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: #ddd,
        dark: #333030,
        light-gray: #f2f2f0
);

//
// GRID
//
$grid-columns: 12;
$grid-gutter-width: 64px;

$grid-breakpoints: (
        //        xs: 0,
        sm: 0,
        md: 700px,
        lg: 1200px,
        xl: 1600px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        md: 65rem,
        lg: 75rem,
        xl: 80rem
);

