.feature-list {
    &__inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & > * {
            width: 33.33%;
            margin-bottom: 1.5rem;
            padding-right: 1rem;
        }
    }
}
