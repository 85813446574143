.short-testimonial {

    margin-bottom: 2rem;

    &__quote {
        font-size: 1.3rem;
        color: #44cc00;
        line-height: 1.6;
    }

    &__author {
        font-size: 0.875rem;
        color: #999;
    }
}
