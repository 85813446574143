
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

@import "variables";

@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';

@import 'notosans';
@import 'notoserif';
@import 'fonts';
@import 'typography';
@import 'layout';

@import 'debug';

@import 'atoms/button/button';
@import 'atoms/contact-link/contact_link';
@import 'atoms/image/image';
@import 'atoms/input/input';
@import 'atoms/intro/intro';
@import 'atoms/section/section';
@import 'atoms/shaded-box/shaded-box';

@import 'molecules/anniversary-badge/anniversary-badge';
@import 'molecules/checklist/checklist';
@import 'molecules/employee/employee';
@import 'molecules/feature/feature';
@import 'molecules/form/form';
@import 'molecules/form-field/form-field';
@import 'molecules/menu-button/menu-button';
@import 'molecules/notices/notices';
@import 'molecules/pagination/pagination';
@import 'molecules/price-box/price-box';
@import 'molecules/row/row';
@import 'molecules/scrollmenu/scrollmenu';
@import 'molecules/social-links/social-links';
@import 'molecules/short-testimonial/short-testimonial';
@import 'molecules/testimonial/testimonial';

@import 'organisms/blog-header/blog-header';
@import 'organisms/blog-item/blog-item';
@import 'organisms/contact-banner/contact-banner';
@import 'organisms/contact-form/contact-form';
@import 'organisms/feature-list/feature-list';
@import 'organisms/footer/footer';
@import 'organisms/navigation/navigation';
@import 'organisms/partner-header/partner-header';
@import 'organisms/pricing/pricing';
@import 'organisms/related-pages/related-pages';
@import 'organisms/showcase/showcase';
@import 'organisms/subnavigation/subnavigation';
@import 'organisms/trial-form/trial-form';

@import 'templates/blog/blog-article';
@import 'templates/blog/blog-page';

@import 'templates/contact-page';
@import 'templates/docs/docs';
@import 'templates/docs-overview';
@import 'templates/feature-page';
@import 'templates/landing-page';
@import 'templates/partner-page';
@import 'templates/testimonials-page';

// Hide recaptcha banner
.grecaptcha-badge {
    visibility: hidden;
}

// Override style for limited promotions // special offers
// @import 'promotions/winter';

