//
// General styles for images
//

.row img, p img {
    max-width: 100%;
    height: auto;
    margin: auto;
    display: block;
}

// full-size thumbnail on lazy-loaded images
img[data-src] {
    //width: 100%;
}

.landing-page, .feature-page, .testimonials-page {
}

// blur up lazy loading images by default
.lazyload {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    transition: filter 500ms, -webkit-filter 500ms;
}

.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}


.image--padded, img.padded {
    padding: 1em;
}

.image--framed, img.framed {
    border: 1px solid #ddd;
    border-radius: 4px;
}

img.right {
    float: right;
    margin-left: 1em !important;
}
