.docs-overview {

    .row {
        margin-bottom: 0;
    }

    .docs-overview__topic {
        background: #f6f6f6;
        padding: 1em;
        border-right: 20px solid white;

        h2 {
            margin-top: 0;
        }

        a:hover {
            text-decoration: none;
        }

        h2 + p {
            color: #888;
        }

        li {
            list-style: none;
        }

        li:last-child {
            font-size: 0.8125em;
        }
    }

    &__contact-links {
        display: flex;
        justify-content: space-between;
        padding: 1em 2em;
        margin-bottom: 2em;
    }

    .contact-link {

    }
}
