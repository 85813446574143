.blog-header {
    height: 17vw;
    min-height: 10em;
    max-height: 25em;
    background: url('https://res.cloudinary.com/timicx/image/upload/v1515510535/backgrounds/blog_bg3.jpg') no-repeat center/cover;
    margin-top: 0;
    margin-bottom: 4em;

    * {
        color: #666;
    }

    &__head {
        font-family: $headings-font-family;
        font-size: 2rem;
        color: inherit;
        margin: 0;
        margin-bottom: 0.3em;
        line-height: 1.15;
    }

    &__subhead {
        color: #aaa;
        margin-bottom: 0;
        line-height: 1;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .container {
        position: relative;
        height: 100%;
    }

    &__title {
        background: white;
        position: absolute;
        bottom: -0.5em;
        padding: 1em 2em 0em 2em;
        left: -1em;

    }
}


