//
// STYLES FOR MARKDOWN NOTICES
.notices {
    margin-top: 1.5em;
    margin-bottom: 1em;
    $base-color: #aaa;
    background: lighten($base-color, 25%);
    border-left: 6px solid $base-color;

    padding: 0.8em 1em;
    box-shadow: $default-box-shadow;

    > p:last-child {
        margin-bottom: 0;
    }

    &.note {
        & > :first-child::before {
            content: "Anmerkung: ";
            font-weight: bold;
        }
    }

    &.tipp {
        $base-color: #4c0;
        border-color: #4c0;
        background-color: rgba(#4c0, 0.2);

        &::before {
            content: "Tipp";
            font-weight: bold;
        }
    }

    &.warning {
        $base-color: orange;
        border-color: $base-color;
        background-color: rgba($base-color, 0.2);

        &::before {
            content: "Hinweis";
            font-weight: bold;
        }
    }

    &.caution {
        $base-color: #d00;
        border-color: $base-color;
        background-color: rgba($base-color, 0.2);

        &::before {
            content: "Warnung";
            font-weight: bold;
        }
    }

}


