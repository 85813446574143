.blog-item {
    display: block;
    padding: 0 1em 1em 1em;
    border: 1px solid #ccc;
    box-shadow: $default-box-shadow;
    margin-bottom: 3em;
    color: $text-color;

    transition-property: border, box-shadow;
    transition-duration: 250ms;
    font-size: 17px;

    h2 {
        font-size: 1.8em;
        margin-bottom: 0.35em;
    }


    &__header-image {
        transition-property: opacity;
        transition-duration: 250ms;
        margin: -1em;
        margin-bottom: 2em;
    }

    &__footer {
        margin-top: 1.5em;
        display: flex;
        justify-content: space-between;
        font-size: 0.9em;
    }

    &__date {
        color: #888;
        white-space: nowrap;
    }

    &__tags {
        margin-left: 1em;
        color: #ccc;
        text-align: right;

        a, a:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    &:hover {
        border-color: #aaa;
        color: $text-color;

        .blog-item__header-image {
            opacity: 0.8
        }
    }

    &:active {
        box-shadow: none;
        color: $text-color;
    }

    &:hover, &:active, &:focus {
        outline: none;
        text-decoration: inherit;
    }

    @include media-breakpoint-up(md) {
        padding: 0em 3em 3em 3em;

        &__header-image {
            margin: -3em;
            margin-bottom: 2em;

            img {
                width: 100%;
            }

        }
    }
}

