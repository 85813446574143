//
// Styling for a form field, including label, input field and error messages
//
// DIV structure:
// .form-field
//     .form-field__label
//     .form-field__field
//         .form-field__input
//         .form-field__hint
//

.form-field {

    $label-width: 6em;
    $label-color: #666;
    $hint-color: #999;
    $text-color: #333;

    //
    // MOBILE FIRST
    //
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 0.75rem;

    &__label {
        // labels have fixed width
        min-width: $label-width;
        margin-bottom: 0.25rem;

        color: $label-color;
    }

    &__field {
        // fill up the remaining horizontal space
        flex: auto;

        // buttons should use the full width in mobile view
        .button {
            width: 100%;
        }

        ::placeholder {
            opacity: 0.4;
        }

    }

    &__input {
        width: 100%;

        // we use 'flex' here to be able to place additional elements to <input>
        // inside .form-field__input
        display: flex;

        input {
            flex: auto;
        }
    }

    &__hint {
        font-size: 13px;
        margin-top: 0.5rem;
        color: $hint-color;
        line-height: 1.3;

        p {
            color: inherit;
        }

        &--error {
            display: none;
            color: red;
            opacity: 0.8;
            line-height: 1.3;
        }
    }

    &--spamprotection {
        display: none !important;
    }

    &--error {
        .form-field__field input, .form-field__field textarea {
            border: 1px solid red;
        }

        .form-field__field input[type=checkbox] {
            outline: 1px solid red;
        }

        .form-field__hint--error {
            display: block; // make visible
        }
    }

    // special styling for checkboxes
    &--checkbox {
        display: block;

        .form-field__label {
            font-size: 0.9375em; // 15px
        }

        .form-field__field {
            display: flex;
            align-items: baseline;

            input[type=checkbox] {
                display: inline-block;
                margin-right: 0.5em;
                flex: none;
            }
        }

        .form-field__hint {

            width: 100%;
        }
    }

    //
    // Small displays (and up)
    //
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin-bottom: 1rem;

        &__label {
            padding-top: 0.5rem;
        }

        &__field {
            .button {
                width: auto;
            }
        }

        // If there is no label, add a margin to align to the other fields
        &__field:first-child {
            margin-left: $label-width;
        }
    }

    //
    // Medium displays (and up)
    //
    @include media-breakpoint-up(md) {
        &__label {
            font-size: 0.9375em;
        }

        &__input {
            font-size: 0.9375em;
        }
    }
}
