.testimonials-page {

    .section--shaded {
        padding: 2rem 0;
    }

    #content, .content {
        padding-bottom: 0;
    }


}
