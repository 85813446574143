
@mixin feature-icon($icon-class) {
    .feature--#{$icon-class} {
        .feature__icon {
            background-image: url('icon_' + $icon-class + '.svg');
        }
    }
}

.feature {
    position: relative;
    border-left: 1px solid theme-color("primary");
    padding-left: 1rem;
    padding-top: 3em;
    line-height: 1.5;

}

.feature__icon {
    position: absolute;
    top: 0;
    left: 0.75rem;
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    background: url('icon_api.svg') no-repeat 50% 50% / contain;
}

@include feature-icon('flexible');
@include feature-icon('secure');
@include feature-icon('safe');
@include feature-icon('mobile');
@include feature-icon('api');
@include feature-icon('support');

.feature__heading {
    position: absolute;
    left: 2.75em;
    top: 0.4em;
    margin: 0 !important;
    display: inline-block;
    font-size: 1.375rem;
}

.feature__text {
    font-size: 0.9375em;
    margin-bottom: 0;
}

@media (min-width: 1240px) {
    .feature--secure {
        &::after {
            content: "";
            background: url(csmig-logo-transparent.png) top left no-repeat;
            background-size: contain;
            height: 60px;
            width: 100px;
            display: block;
            position: absolute;
            top: 3em;
            right: -130px;
        }
    }
}


