.button {
    $default-text-color: #444;
    $default-base-color: #ddd;
    $default-vivid-color: #e6e6e6;

    $primary-base-color: #44cc00;
    $primary-vivid-color: #50d900;
    $primary-muted-color: #40bf00;
    $primary-text-color: white;

    display: inline-block;

    background-color: $default-base-color;
    color: $default-text-color;
    border: 1px solid $default-base-color;
    border-radius: $border-radius;
    padding: 0.5em 1em;
    text-decoration: none;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: background-color 300ms, color 300ms;

    &:hover {
        text-decoration: none;
        background-color: $default-vivid-color;
        color: $default-text-color;
    }

    &:active {
        box-shadow: 0px 0px 0.25em rgba(0, 0, 0, 0.25);
    }

    &--primary {
        background-color: $primary-base-color;
        border-color: $primary-base-color;

        color: $primary-text-color;
        font-weight: bold;

        &:hover {
            background-color: $primary-vivid-color;
            color: $primary-text-color;
        }
    }

    &--primary[disabled] {
        background-color: lighten(desaturate($primary-base-color, 100%), 30%);
        border-color: lighten(desaturate($primary-vivid-color, 100%), 30%);
        cursor: wait;
    }

    &--outline {
        background-color: transparent;
        color: $primary-muted-color;
        border-color: $primary-muted-color;

        &:hover, &.button--active {
            background-color: $primary-base-color;
            color: $primary-text-color;
        }

    }

    &--dropdown {
        padding-right: 2em;

        &::after {
            position: absolute;
            display: block;
            line-height: 1;
            right: 0.75em;
            top: calc(50% - 0.5em);
            z-index: 100;
            content: "▼";
        }
    }

    &--dropdown-expanded {
        &::after {
            content: "▲";
        }
    }

    &--small {
        font-size: 0.875em;
        padding: 0.125em 0.5em;
    }
}
