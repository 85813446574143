.landing-page {
    .row {
        margin-bottom: $grid-gutter-width * 1.5;
    }
    .tour .button {
        margin-top: 0.75rem;
    }

    .row.testimonials {
        background: #f6f6f6;
        padding: 1.5em 1em;


        h3, p {
            text-align: center;
        }

        img {
            margin-top: 0.5em;
        }

        .col-md-4 strong {
            color: #888;
        }

        .button {
            margin-bottom: 1.5em;
        }
    }
}
