.scrollmenu {
    position: absolute;   //fallback for browsers without "sticky" support (IE11)
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;

    // Prevent space preservation for the menu
    // (for a non-sticky element, we'd normally would use "position:absolute" here)
    float: right;

    left: 100%;
    width: 16em;
    margin-right: -16em;
    padding-left: 0;
    padding-left: 3rem;

    font-size: 0.8125rem;

    @media (max-width: 1500px){
        display: none;
    }

    &__item {
        list-style: none;
        border-left: 3px solid #ccc;
        padding: 0.15em 0 0.15em 0.5em;
        margin-bottom: 0.4em !important;

        a {
            color: #aaa;
            margin: 0;
        }

        &:hover {
            border-color: #aaa;

            a {
                color: darken($text-color, 5);
                text-decoration: none;
                color: inherit;
            }
        }

        &--active, &--active:hover {

            a, a:hover {
                color: #44cc00;
            }

            font-weight: bold;
            border-color: #44cc00;
        }
    }


}
