//
// GLOBAL STYLES
// =============

body {
    font-size: 15px;
    color: $text-color;
    line-height: 1.6;

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}


//
// HEADINGS
// ========

h1, h2, h3, h4 {
    font-family: $headings-font-family;
    font-variant-ligatures: none;
    font-weight: normal;
    line-height: 1.25em;
}

h1 {
    color: #44cc00;
    font-size: 2.7rem;
    margin-top: 1.7em;
    margin-bottom: 0.8em;

    @include media-breakpoint-down(sm) {
        font-size: 2.375rem;
    }

}

h2 {
    color: #44cc00;
    font-size: 2.2rem;
    margin-top: 1.4em;
    margin-bottom: 0.7em;

    @include media-breakpoint-down(sm) {
        font-size: 1.9rem;
    }

}

h3 {
    color: black;
    font-size: 1.5rem;
    margin-top: 1.4em;
    margin-bottom: 0.6em;

    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }

}

h4 {
    color: black;
    font-size: 1.2rem;
    font-weight: normal;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

h5 {
    color: black;
    font-size: 1.2rem; // 18px
}

//
// BODY COPY
// =========
.content {
    p, q, li {
        margin-bottom: 0.6em;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    dl {
        margin-left: 1.5em;
    }

    dd {
        margin-left: 2.5em;
    }
}
