.footer {
    background-color: $footer-bg-color;
    color: $footer-text-color;
    padding-top: 3rem;
    padding-bottom: 4rem;
    font-size: 0.9375rem;
}

.footer .row, .footer .row > *{
    margin-bottom: 0 !important;
}

.footer__brand {
    margin-bottom: 1.5rem !important;
}

.footer__logo {
    background: url('footer_logo.svg') no-repeat 0 center / contain;
    height: 1.5rem;
    opacity: 0.5;
    text-indent: -10000em;
}

.footer__address {
    margin-top: 1em;
    opacity: 0.3;
    font-size: 0.8125rem;

    @include media-breakpoint-down(md) {
        display: none;
    }
    p, a, a:hover, a:active {
        color: white;
    }
}

.footer__category:last-child {
    opacity: 0.55;
}
.footer__category > * {
    padding: 0;


}

.footer__category-header {
    opacity: 0.7;
    display: block;
    margin-bottom: 0.25em;
}

.footer__category-item {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        display: inline-block;
        color: $footer-text-color;
        opacity: 0.6;
        margin: 0.125em 0;
    }

}
