//
// Styling of a single "employee description" on the team page
.employee {

    margin-bottom: 2rem;

    &__photo  {
        img {
            border-radius: 50%;
            border: 1px solid #ccc;
        }
        width: 12rem;
        text-align: center;
        margin: 0 auto 2rem;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;

        &__photo {
            margin-right: 1rem;
            width: 10rem;
        }

        &__text {
            flex: 1;
        }
    }

    @include media-breakpoint-up(lg) {
        &__photo {
            width: 8rem;
        }
    }
}
