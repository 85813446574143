.docs-pagelist {
    background: #f6f6f6;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 2em;
    margin-bottom: 2em;

    &__head {
        padding: 0.25em 1em;
        font-weight: bold;
        color: #555;
        background-color: #ddd;
        border-bottom: 1px solid #ddd;
    }

    &__body {
        padding: 1em;

        ul {
            margin: 0;
            padding: 0;
        }
    }

    &__item {
        list-style: none;
        margin-bottom: 0.45em;
        background: url("icon_page_white.png") left 0.15em no-repeat;
        padding-left: 20px;
    }

    &__item-desc {
        color: #888;
        font-size: 14px;
        margin-bottom: 1em;
    }
}
