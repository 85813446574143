.anniversary-badge {
    display: none; // hide by default
}

.landing-page .anniversary-badge {
    $width: 3.3em;
    $height: 5.5em;

    position: absolute;
    display: none; // hide by default
    z-index: 1000;
    background: #00A4EB;
    top: 0;
    right: -11em;
    width: $width;
    height: $height;

    color: #fff;
    text-align: center;

    @media (min-width: 1240px) {
        display: block;
        right: -7em;
    }

    @include media-breakpoint-up(xl) {
        left: -8em;
    }

    &__years {
        margin-top: 0.9em;
        font-size: 1.875em;
        font-weight: bold;
        line-height: 1em;
        opacity: 0.9;
    }

    &__subline {
        font-size: 0.9375em;
        padding-left: 0.2em;
    }

    &::after {
        content: "";
        display: block;
        top: $height;
        position: absolute;
        border-style: solid;
        border-width: 0 $width 15px 0;
        border-color: transparent #00A4EB transparent;
    }
}
