.section {

    z-index: 10;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 2rem;

    &--shaded {
        background-color: #f2f2f0;
        z-index: 0;
    }

}

.section > .container {
    position: relative;
}
.section .container > .row:first-child {
    margin-top: 0;
}

.section .container > .row:last-child {
    margin-bottom: 0;
}

