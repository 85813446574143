//
// STYLES FOR DOCUMENTATION PAGES
@import "breadcrumbs";
@import "pagelist";
@import "pagenav";
@import "tables";
@import "toc";

.docs {
    // show always scrollbar to prevent layout "jumping"
    overflow-y: scroll;

    &__wrap {
        display: flex;
    }

    &__toc {
        min-width: 22%;
        margin-right: 3.5em;
    }

    &__content {
        flex: auto
    }

    article {
        font-size: 16px;

        h1 {
            font-size: 2.5em;
            color: #44cc00;
            margin-top: 0.5em;
            margin-bottom: 0.8em;
        }

        h2 {
            color: #555;
            font-size: 1.6em; // 44px
            margin-top: 1.5em;
            margin-bottom: 0.5em;
            border-bottom: 1px solid #ddd;
        }

        ul li {
            list-style: square;
            margin-bottom: 0.5em;
        }

        color: #444;

        strong, dt {
            color: #444;
        }

        ol li + li {
            margin-top: 1.5em;
        }

        p code, td code, li code {
            background-color: #f0f0f0;
            border-radius: 2px;
            padding: 2px;
        }

        img {
            max-width: 100%;
        }

    }

    .hljs {
        border-radius: 2px;
    }

    // HTTP Requests in API documentation
    .hljs.language-http {
        font-weight: bold;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        * { color: #eee !important; }
    }

}
