.blog-page {
    aside {
        margin-right: 2em;
        //margin-top: 5em;

        h4 {
            font-size: 1.25rem;
            color: rgba(black, 0.6);
            border-bottom: 1px solid #ccc;
        }

        .button {
            text-transform: uppercase;
            margin-bottom: 0.5em;
        }
    }
}

