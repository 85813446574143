.docs-pagenav {
    margin-top: 3em;
    margin-bottom: 4em;
    display: flex;
    justify-content: space-between;
    font-size: 0.9375em;
    border-top: 1px solid #ddd;
    padding-top: 0.5em;

    a, a:hover {
        color: #999;
        text-decoration: none;
    }

    a:hover {
        color: #666;
    }

    &__next {
        float: right
    }

    &__prev::before {
        color: #44cc00;
        content: "❮ "
    }

    &__next::after {
        color: #44cc00;
        content: " ❯"
    }

}
