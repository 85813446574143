.docs-breadcrumbs {
    min-height: 1.6em;
    margin-top: 1.7em;

    &__item  {
        font-size: 0.9375em;
        color: #999;

        &:hover {
            text-decoration: none;
            color: #666;
        }
    }

    &__item + &__item::before {
        content: " ›  ";
        color: #999;
        padding: 0 0.125em;
    }
}
