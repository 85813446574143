.docs-toc {
    // title
    &__header {
        display: block;
        color: #999;
        font-size: 1.25em;
        margin: 1.25em 0 0.75em 0;

        &:hover {
            color: #999;
            text-decoration: none;
        }
    }

    // list represents an <ul> level
    &__list {
        font-size: 0.875rem;
        padding: 0;
        margin: 0;
    }

    // a single navigation item <li>
    &__item {
        position: relative;
        list-style: none;
        margin: 0.5em 0;
        line-height: 1.25;

        a {
            hyphens: none;
            display: block;
            color: #666;
        }

        // root items
        &--level-1 {
            margin-bottom: 2em !important;

            & > a {
                padding: 0.5em 1em;
                font-family: $headings-font-family;
                font-size: 1.25em;
                color: #555;
                background: #ddd;
                border-radius: 0.125em;

                &:hover {
                    text-decoration: none;
                }
            }

            // highlight current item
            &.docs-toc__item--active-ancestor > a, &.docs-toc__item--active > a {
                background: theme-color("primary");
                color: rgba(#fff, 0.9);
                font-weight: normal;
            }

            & > .docs-toc__expand-trigger {
                $size: 2em;
                width: $size;
                height: $size;

                cursor: pointer;
                display: inline-block;
                position: absolute;
                right: 0.5em;
                top: 0.3em;
                content: "";
                background: url("angle-up.svg") left center no-repeat;
                background-size: $size;
                opacity: 0.3;
            }

            &.docs-toc__item--collapsed > .docs-toc__expand-trigger {
                background-image: url("angle-down.svg")
            }

        }

        // list inside list
        .docs-toc__list {
            margin-left: 1rem;
        }

        // item inside item
        .docs-toc__item {


            // current page
            &--active > a {
                color: #333;
            }

            // current page's parents
            &--active-ancestor > a {
                color: #222;
            }

            &--expandable > .docs-toc__expand-trigger {
                cursor: pointer;
                display: inline-block;
                position: absolute;
                left: -1.3em;
                width: 1em;
                height: 1.25em;
                content: "";
                background: url("angle-down.svg") left center no-repeat;
                background-size: 1.2em;
                opacity: 0.5;
            }

            &--collapsed > .docs-toc__expand-trigger {
                background-image: url("angle-right.svg")
            }

        }



        // active item (current page)
        &--active {
            & > a {
                font-weight: bold;
            }
        }
        &--collapsed {
            ul {
                display: none;
            }
        }
    }
}
