.testimonial {

    font-size: 0.875em;
    background-color: #fff;
    border-radius: 4px;

    margin: 3em auto;
    padding: 3em 2em;

    position: relative;

    p {
        hyphens: none;
    }

    .company-name {
        font-weight: bold;
        margin-bottom: 0.3em;
    }

    .company-link {
        margin-bottom: 0.5em;
    }

    .company-field {
        font-size: 0.9em;
        line-height: 18px;
        color: #555;
    }

    blockquote p {
        font-size: 1.2143em;
        line-height: 1.6;
    }

    .person {
        color: #999;
        font-size: 15px;
        margin-top: 8px;
    }

    img {
        width: auto;
        height: auto;
        margin-bottom: 1em;
    }


}
