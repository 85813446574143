.partner-page {
    h3 {
        font-size: 1.7em;
    }

    @include media-breakpoint-only(lg) {
        h3 {
            font-size: 1.45em;
        }

        h4 {
            font-size: 1.25em;
        }
    }

    .content .button--primary {
        margin-top: 1em;
    }

    .row {
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-bottom: 6em;
        }
    }

    @include media-breakpoint-down(sm) {
        .mayhide {
            display: none;
        }
    }
}
