.partner-header {

    position: relative;

    background-image: url('https://res.cloudinary.com/timicx/image/upload/c_fill,e_improve,h_800,w_1920/v1558764004/backgrounds/calculator_bg.jpg');
    background-repeat: no-repeat;
    background-size: auto 20em;
    background-position: center top;

    padding-top: 15em;

    &__logo {
        img {
            max-width: 12em;
            min-height: 4em;
            object-fit: contain;
            position: absolute;
            top: 1.8em;
            left: 1em;
        }
    }

    &__content {
        background: white;
        margin: -2em;
        margin-top: -2em;
        padding: 2em 1em;

        .overline {
            font-family: $headings-font-family;
            font-size: 1.6em;
            line-height: 1.2;
            color: #B5B5B5;
            margin-bottom: 0.25em;
            hyphens: none;
            -webkit-hyphens: none;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        h1 {
            margin-top: 0;
        }

        .intro {
            font-size: 21px;
        }
    }

    @include media-breakpoint-only(md) {
        background-size: auto 25em;
        margin-bottom: 2em;
    }

    @include media-breakpoint-up(lg) {
        height: 27em;
        padding-top: 0;
        background-size: cover;
        background-position: center 60%;
        margin-bottom: 7em;

        &__logo {
            position: absolute;
            background-color: #F2F2F0;
            bottom: 0;
            left: 0;
            right: 0;

            .container {
                position: relative;
                height: 7em;
            }

            img {
                display: block;
                width: 12em;
                right: 0;
                top: 1.5em;
                left: auto;
            }
        }

        &__content {
            position: absolute;
            width: 46em;
            padding: 2em 3em 0 3em;
            border-radius: 3px;

            margin: -3em;
            bottom: -1em;
        }
    }

    @include media-breakpoint-up(xl) {
        &__logo img {
            right: 6vw;
        }
    }
}
