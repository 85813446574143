.showcase {
    //
    // MOBILE FIRST: BASIC SETTINGS FOR ALL SIZES
    //

    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 2rem;

    $bg_img: 'startpage_bg.jpg';

    background: #f2f2f0 url($bg_img) no-repeat 82% top / auto 21rem;

    &__teaser {

        h1 {
            font-size: 2.375rem;
            line-height: 1.1;
            margin-top: 0;
            margin-bottom: 1rem;
            max-width: 9em;
            hyphens: none;
            -webkit-hyphens: none;
        }

        p.intro {
            font-size: 1.125rem;
            line-height: 1.4;
            hyphens: none;
            -webkit-hyphens: none;
            margin-bottom: 0.5rem;
        }

        .button {
            //font-size: 1.0625rem;
            margin-top: 0.75rem;
            margin-right: 0.5rem;
        }
    }

    .feature-list {
        display: none;
    }

    // SMALL SCREEN ONLY
    @include media-breakpoint-only(sm) {
        background-position: 93% top;
        background-size: auto 75vw;

        &__teaser {
            margin-top: 45vw;

            h1 {
                font-size: 7.5vw;
                max-width: none;
            }

            p.intro {
                font-size: 1.25rem;
            }
        }
    }

    //
    // MEDIUM SCREEN AND UP
    //
    @include media-breakpoint-up(md) {
        background-size: auto 37rem;
        background-position: 54% top;

        &__teaser {
            position: absolute;
            z-index: 10;
            background-color: rgba(white, 0.95);
            padding: 1.5rem;
            margin-left: -1.5rem;

            h1 {
                font-size: 2.75rem;
                max-width: none;
            }

            p.intro {
                font-size: 1.1875rem;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
            }
        }

        .feature-list {
            padding-top: 23rem;
            display: flex;
        }
    }

    // MEDIUM SCREEN ONLY
    @include media-breakpoint-only(md) {
        &__teaser {
            width: 34rem;
            top: 0;
            padding-top: 2rem;
        }
    }

    //
    // LARGE AND UP
    //
    @include media-breakpoint-up(lg) {
        background-size: auto 65%;
        background-position: 50% top;

        &__teaser {
            top: 0;
            left: 0.5rem;
            width: 40rem;
            padding: 2rem 1.5rem 1.5rem 2rem;

            h1 {
                max-width: 12em;
            }
            p.intro {
                line-height: 1.5;
            }
        }
    }

    //
    // X-LARGE AND UP
    //
    @include media-breakpoint-up(xl) {
        //background-size: auto 32vw;
        background-size: 110%;
        background-position: 60% top;

        &__teaser {
            width: 41rem;

        }

        .feature-list {
            padding-top: 22rem;
        }
    }
}

