.price-box {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;

    &__header {
        background: #828282;
        color: white;
        font-size: 1.375em;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 0.5em;
        margin: 0;
        cursor: pointer;
    }

    &__body {
        padding: 1.5em 1em;
        text-align: center;
        position: relative;
        display: none; // hide by default in mobile view
    }

    &:first-child .price-box__body {
        display: block;
    }

    &__eyecatcher {
        position: absolute;
        background: #ff9900;
        color: white;
        font-weight: bold;
        font-family: $headings-font-family;
        padding: 0.1em;
        top: -0.6em;
        left: 20%;
        right: 20%;
    }

    &__price {
        font-family: $headings-font-family;
        font-size: 2.375em;
        margin-top: 0.25em;
        line-height: 1;
        letter-spacing: -0.03em;
    }

    &__unit {
        color: #999;
        margin-top: 0.3em;
        margin-bottom: 1em;
    }

    &__text {
        min-height: 3.2em;
        line-height: 1.2;
    }

    @include media-breakpoint-up(md) {
        &__header {
            cursor: inherit;
        }

        &__body {
            display: block !important;
        }
    }
}
