.pagination {
    padding: 1em 0 0;
    display: flex;
    justify-content: center;

    li {
        list-style: none;
    }

    span, a {
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        padding: 0.25em 0.75em;
        margin: 0.15em;
        display: inline-block;
        font-weight: bold;
    }

    a {
        transition-property: background;
        transition-duration: 150ms;
        color: #4c0;
    }

    a:hover {
        text-decoration: none;
        background: #4c0;
        color: white;
    }
}
