/**
 * STYLING FOR THE NAVIGATION BAR
 */

// VARIABLES
$navigation-bg-color: #fff !default;
$navigation-height: 3.5rem !default;

//
// OVERALL STRUCTURE
//
.navigation {
    background-color: $navigation-bg-color;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: $default-box-shadow;
}

.navigation__inner {
    @include make-container();
    @include make-container-max-widths();
    // TODO 2024: other sizes?
    max-width: 80rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: $navigation-height;
    padding: 0.5rem 1rem;
}

.navigation__logo {
    background: url('logo_dark.svg') no-repeat 0 center / contain;
    width: 8em;
    height: 1.75em;
    text-indent: -10000em;
}

//
// MENUS
//

.navigation__menu {
    font-family: $font-family-base;
    margin: 0;
    padding: 0;
}

.navigation__menu--primary {
    flex: auto;
    margin-left: 0.75rem;

    display: none;

    @media screen and (min-width: 480px) {
      display: block;
    }
}

.navigation__menu--secondary {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

//
// MENU ITEMS
//
.navigation__menu-item {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;

    a {
        display: inline-block;
        color: #666;
        transition: all 200ms;
    }

    a:hover, a:active {
        text-decoration: none;
        opacity: 1;
    }

    &--primary {
        float: left;
        margin-top: 0.15rem;
        font-size: 1.125rem;

        a {
            opacity: 0.9;
            line-height: 1.1;
            padding: 0rem 0.5rem;
            border-right: 1px solid #44cc00;
        }

        &:last-child a {
            border-right: none;
        }
    }

    &--secondary {
        font-size: 0.9375rem;

        &:not(:last-child) a {
            opacity: 0.7;
            padding: 0 0.4em;

            &:hover {
                opacity: 0.9;
            }
        }

        // mute green on dark background
        .button--primary {
            color: white;
            background-color: #44cc00;
            border-color: #44cc00;
            margin-left: 0.4em;
            font-size: inherit;

            &:hover {
                background-color: #4c0;
            }
        }
    }

    &--active a {
        font-weight: bold;
        color: #44cc00
    }
}


//
// MOBILE MENU
//

.navigation__menu-icon {
    position: relative;
    border: none;
    border-radius: $border-radius;
    text-indent: 10000em;
    width: 2.5em;
    height: 2.5em;
    opacity: 0.8;
    cursor: pointer;

    // make an "Hamburger" icon
    &:before {
        content: "";
        position: absolute;
        left: 0.5em;
        top: 0.7em;
        width: 1.375em;
        height: 0.2em;
        background: #666;
        box-shadow: 0 0.55em 0 0 #666, 0 1.1em 0 0 #666;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.navigation__menu--mobile {
    position: absolute;
    width: 100%;
    background: rgba($navigation-bg-color, 0.95);
    padding: 0;
    display: none; // hidden by default
    box-shadow: $default-box-shadow;
}

.navigation__mobile-menu-item {
    list-style: none;
    margin: 0;
    border-bottom: 1px solid rgba(white, 0.3);

    a {
        display: inline-block;
        font-size: 1.0625em;
        padding: 0.5rem 1rem;
        width: 100%;
        color: #333;
        opacity: 0.9;
    }

    a:hover, a:active {
        background-color: rgba(white, 0.1);
        text-decoration: none;
        opacity: 1;
    }
}

.navigation__mobile-menu-item--primary {
    @media screen and (min-width: 480px) {
        display: block;
    }
}

