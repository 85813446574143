
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400%3B0,700%3B1,400&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400%3B0,700%3B1,400&display=swap');


@font-face {
    font-family: 'Fago';
    font-weight: normal;
    src: url('../fonts/Fago/Fago.eot');
    src: url('../fonts/Fago/Fago.eot?#iefix') format('embedded-opentype'), url('../fonts/Fago/Fago.woff2') format('woff2'), url('../fonts/Fago/Fago.woff') format('woff'), url('../fonts/Fago/Fago.ttf') format('truetype');
}

@font-face {
    font-family: 'Fago';
    font-weight: bold;
    src: url('../fonts/Fago/FagoBold.eot');
    src: url('../fonts/Fago/FagoBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Fago/FagoBold.woff2') format('woff2'), url('../fonts/Fago/FagoBold.woff') format('woff'), url('../fonts/Fago/FagoBold.ttf') format('truetype');
}

