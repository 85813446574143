.blog-article {
    &__title {
        margin-top: 8em;
        margin-bottom: -1em;
    }

    &__text {
        font-family: 'Noto Serif', $font-family-base;
        font-size: 18px;
        line-height: 1.8;
        color: #555;

        h2 {
            font-size: 1.7rem;
            margin-top: 2.5em;
            margin-bottom: 0.8em;
            color: black;
        }

        p {
            margin-bottom: 1.5em;
            hyphens: none !important;
            -webkit-hyphens: none;
        }

        // First paragraph as intro
        > p:first-of-type {
            @extend .intro;
            line-height: 1.8;
        }

        p > img {
            display: block;
            margin: 1em auto;
        }

        // more margin around solo images
        p > img:first-child:last-child, p > a[rel='lightbox'] img, & > img {
            margin: 3em auto;
        }

        // Captions underneath images
        figcaption {
            font-size: 0.8333em;
            color: #888;
            font-family: $font-family-base;
            margin-bottom: 3em;
            margin-top: -2.5em;
            line-height: 1.6;
        }
    }

    &__trailer {
        border-bottom: 1px solid #ccc;
        margin-top: 2em;
        padding-bottom: 1em;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
    }

    &__date {
        color: #999 !important;
        text-transform: uppercase;
    }

    &__footer {

        h4 {
            color: #999;
            margin-bottom: 0.5em;
        }

        @include media-breakpoint-up(md) {
            margin-top: 4em;
        }
    }
}
