$vertical-gutter: 80px;

.row {
    margin-bottom: $vertical-gutter;
//border: 2px solid lightseagreen;
    //border: 1px solid deeppink;

    [class^='col'] {
        // No margin above the first element in a column
        :first-child {
            margin-top: 0;
        }
        //border: 1px dashed lightsalmon;
        //margin-bottom: $vertical-gutter;
    }

    .row {
        margin-bottom: calc($vertical-gutter / 2);
    }
    @include media-breakpoint-down(sm) {
        //margin-bottom: $vertical-gutter / 2;
    }

}
