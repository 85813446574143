.social-links {

    &__icon {
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        text-indent: -200vw;
        background-size: contain;
        margin-right: 0.25em;
    }

    // Icons from https://simpleicons.org/

    &--facebook {
        background-image: url('facebook.svg');
    }

    &--linkedin {
        background-image: url('linkedin.svg');
    }

    &--twitter {
        background-image: url('twitter.svg');
    }

    &--vimeo {
        background-image: url('vimeo.svg');
    }

    &--xing {
        background-image: url('xing.svg');
    }

}
