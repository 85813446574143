.contact-banner {

    margin-bottom: 2rem;

    &__headline {
        font-size: 1.3125em;
        color: $text-color;
        max-width: 25em;
        margin-bottom: 1em;
    }

    &__links {
        margin-top: 1em;
        color: #666;
    }

    .contact-link {
        margin-right: 2.5em;
        margin-bottom: 0.75em;

        &:last-child {
            margin-right: 0;
        }
    }

    &__test {
        p {
            font-size: 0.9375em;
        }
        .button {
            width: 100%;

            @include media-breakpoint-only('md') {
                .mayhide {
                    display: none;
                }
            }
        }
    }
}
